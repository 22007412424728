<template>
  <!-- Group Actions -->
  <div v-if="bIsGroup || !relation || bIsParentSame" class="avv-group buttons">
    <template v-if="relation && bIsGroup && !simpleWidgetMatch">
      <button
        class="avv-button avv-radio start"
        :class="{ active: relationName === 'And' }"
        @click="relationName = 'And'"
      >
        And
      </button>
      <button
        class="avv-button avv-radio end"
        :class="{ active: relationName === 'Or' }"
        @click="relationName = 'Or'"
      >
        Or
      </button>
      <button class="close" @click="removeRelation">
        <i class="material-icons" aria-hidden="true">close</i>
      </button>
    </template>
    <div style="flex: 1" />
    <button
      v-if="!simpleWidgetMatch || isMain"
      class="avv-button avv-float"
      :data-open="bShowFloatingMenu"
      @click="bShowFloatingMenu = !bShowFloatingMenu"
    >
      <i aria-hidden="true" class="material-icons">add</i>
      <div class="menu" @click.prevent>
        <button class="item" @click="onCreateGroup">
          {{ localizeText('general.ast_actions.add_group') }}
        </button>
        <button class="item" @click="onCreateCondition">
          {{ localizeText('general.ast_actions.add_condition') }}
        </button>
      </div>
    </button>
  </div>
  <!-- END Group Actions -->

  <template
    v-if="
      simpleWidgetMatch &&
      simpleWidgetMatch.widget != null &&
      simpleWidgetMatch.info === ''
    "
  >
    <component
      :is="simpleWidgetMatch.widget"
      :uuid="uuid"
      :parent="parent"
      :readonly="false"
    />
  </template>

  <!-- Nested groups -->
  <div
    v-else-if="relation && bIsGroup && bIsIterable"
    :class="{ 'ast-group': bIsGroup, [relationName]: relation != null }"
    class="avv-group"
  >
    <CustomConditionBuilder
      v-for="childUUID in relationValue"
      :key="childUUID"
      :uuid="childUUID"
      :parent="uuid"
      :show-widgets="showWidgets"
    />
  </div>
  <!-- END Nested groups -->

  <template v-else-if="relation">
    <div v-if="bIsIterable" class="avv-group">
      <CustomConditionBuilder
        v-if="relationValue[0] !== undefined"
        :uuid="relationValue[0]"
        :parent="uuid"
        :show-widgets="showWidgets"
      />
      <div class="Comparison">
        <AvvSelect
          ref="selectCompEl"
          :value="relationName"
          @select="onSelectValue"
        >
          <div class="menu">
            <avv-option
              v-for="(value, key) in selectValues"
              :key="value"
              :value="key"
              @click.prevent
              v-text="parseValue(value.name)"
            />
          </div>
        </AvvSelect>
      </div>
      <CustomConditionBuilder
        v-if="relationValue[1] !== undefined"
        :uuid="relationValue[1]"
        :parent="uuid"
        :show-widgets="showWidgets"
      />
      <button class="close" @click="removeRelation">
        <i class="material-icons" aria-hidden="true">close</i>
      </button>
    </div>
    <div v-else :class="{ [relationName]: relation != null }">
      <template v-if="relationName == 'Att'">
        <AvvSelect
          ref="selectCompEl"
          :placeholder="`Choose or add ${parseRelation(relationName)}`"
          :value="relationValue"
          @select="onSelectValue"
          @open="onOpenCustomSelect"
        >
          <div class="menu">
            <template v-for="(value, index) in selectValues" :key="value">
              <avv-option
                :title="value"
                :value="value"
                @click.prevent
                v-text="value"
              />
              <div
                v-if="isLastSystem(index, value) && type == 'date'"
                class="separator"
              ></div>
            </template>
          </div>
        </AvvSelect>
      </template>
      <template v-else-if="type == 'date'">
        <DDatePicker
          avv-style
          :model-value="relationValue"
          @update:model-value="onSelectValue"
        ></DDatePicker>
      </template>
      <template v-else>
        <AvvSelect
          ref="selectCompEl"
          :placeholder="`Choose or add ${parseRelation(relationName)}`"
          :value="relationValue"
          @select="onSelectValue"
          @open="onOpenCustomSelect"
        >
          <div class="menu">
            <div v-if="type !== 'states'" class="custom-input z-10">
              <label>
                <input
                  v-model="selectCustomValue"
                  type="text"
                  class="avv-input"
                  :placeholder="
                    localizeText('questionnaire.select.another_option')
                  "
                  @keydown="onSubmitSelectCustomValue"
                />
              </label>
              <button
                type="button"
                class="avv-button"
                @click="submitCustomValue"
              >
                <i class="material-icons" aria-hidden="true">add</i>
              </button>
            </div>
            <avv-option
              v-for="value in selectValues"
              :key="value"
              :title="value"
              :value="value"
              @click.prevent
            >
              {{ value }}
            </avv-option>
          </div>
        </AvvSelect>
      </template>
    </div>
  </template>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  ref,
  watch,
  nextTick,
  toRefs
} from 'vue'
import {
  AST_FUNCTIONS,
  AstGroupComparison,
  AstStore,
  type AstStoreType,
  type SuperAstType,
  AST_TEMPLATES
} from '../ast'
import AvvSelect from '../../reusable/components/AvvSelect.vue'
import { type Store } from 'vuex'
import { useAstBuilder } from './Reusable'
import DDatePicker from '../../add_datasheet_row/components/DDatePicker.vue'
import {
  systemAttributeToKey,
  isDateAttribute,
  DEFAULT_OPERATOR,
  type FilterType
} from '../../custom_reports/store'
import { DOMEmit } from '../../dom_utils'
import { DEFAULT_DATE_WITHIN_AST } from '../../custom_reports/store'

export default defineComponent({
  name: 'CustomConditionBuilder',
  components: { AvvSelect, DDatePicker },
  props: ['uuid', 'parent', 'readonly', 'showWidgets', 'dataCondition'],
  setup(props) {
    const { uuid, parent, dataCondition, showWidgets } = toRefs(props)
    const type = inject('type') as FilterType
    const store = inject(AstStore) as Store<AstStoreType>
    const ast = useAstBuilder(store, uuid, parent, false)
    const isMain = computed(() => uuid.value == store.state.main)
    const bSelectIsOperation = computed(
      () => ast.bIsGroup.value || ast.bIsIterable.value
    )
    const parseValue = (value: string) => {
      let parsedValue = null
      if (type == 'date') {
        parsedValue = { 'Greater than': 'From', Less: 'To' }[value] ?? value
      }
      if (['states', 'workflow'].includes(type)) {
        parsedValue = { Equals: 'Is', 'Not equals': 'Is not' }[value]
      }
      return parsedValue ?? value
    }

    const isLastSystem = (index: number) => {
      const bIsAttribute = ast.relationName.value.indexOf('Att') !== -1
      const value = ast.relationValue.value
      if (!value || isDateAttribute(systemAttributeToKey(value)))
        return index == 5
      else return index == 6
    }

    const simpleWidgetMatch = computed(
      () =>
        props.showWidgets &&
        Ast.matchWidget({
          main: uuid.value,
          byUUID: store.state.byUUID,
          ast_menu: null
        })
    )

    /** Floating menu */
    const bShowFloatingMenu = ref<boolean>()
    const onCreateGroup = () => {
      // ast is empty
      if (!ast.relation.value) {
        ast.setRelation({ And: [] })
        return
      }

      // If ast contains single non-group element then wrap the element to group
      if (
        (ast.relation.value && !ast.bIsGroup.value) ||
        simpleWidgetMatch.value
      ) {
        ast.wrapRelation('And')
        return
      }

      ast.appendRelation({ And: [] })
    }
    const onCreateCondition = () => {
      const defaultValue = [{ Att: '' }, { String: '' }]
      const value = {}
      value[DEFAULT_OPERATOR(type)] = defaultValue

      // ast is empty
      if (!ast.relation.value) {
        ast.setRelation(value)
        return
      }

      // If ast contains single non-group element then wrap the element to group
      if (
        (ast.relation.value && !ast.bIsGroup.value) ||
        simpleWidgetMatch.value
      ) {
        ast.wrapRelation('And')
      }

      nextTick(() => {
        // Insert condition
        ast.appendRelation(value)
      })
    }

    /** ------------- */

    /** Select */
    const selectCompEl = ref<{ selectEl: HTMLElement }>()
    const onSelectValue = (option: HTMLElement | string) => {
      const value =
        typeof option == 'string' ? option : option.getAttribute('value')
      if (bSelectIsOperation.value) {
        if (value == 'Within') {
          const att = store.state.byUUID[ast.relationValue.value[0]]['Att']
          const newAst = DEFAULT_DATE_WITHIN_AST
          let valueWithAtt = newAst
          if (att) {
            const attString = `'Att':'${att}'`
            valueWithAtt = newAst.replaceAll("'Att':''", attString)
          }
          const newAstObject = Ast.parse(valueWithAtt)!.ast
          const astObject = store.getters.astObject
          const paths = store.getters.pathToUuid(uuid.value)
          const replacedAst = Ast.stringify(
            Ast.replace(astObject, paths, newAstObject)
          )
          DOMEmit('toggle-ast', { astString: replacedAst })
        } else ast.relationName.value = value
      } else {
        ast.relationValue.value = value
      }
    }
    const onOpenCustomSelect = (option: HTMLElement) => {
      if (
        globalThis.avv_select_get_selected(selectCompEl.value.selectEl) == null
      )
        selectCompEl.value.selectEl.querySelector('input')?.focus()
    }

    const selectCustomValue = ref<string>('')
    const submitCustomValue = () => {
      let newValue = selectCustomValue.value
      if (!newValue) return
      selectCustomValue.value = ''

      /** Value is bubbling to blot */
      nextTick(() => {
        globalThis.avv_select_close(selectCompEl.value.selectEl)
        const bIsAttribute = ast.relationName.value.indexOf('Att') !== -1
        if (bIsAttribute) {
          const existingValue = store.state.attributes.find(
            (item) => String(item).trim() === String(newValue).trim()
          )
          if (existingValue !== undefined) newValue = existingValue
          else store.state.attributes.push(newValue)
        } else {
          const existingValue = store.state.values.find(
            (item) =>
              String(item).toLowerCase().trim() ===
              String(newValue).toLowerCase().trim()
          )
          if (existingValue !== undefined) newValue = existingValue
          else store.state.values.push(newValue)
        }
        ast.relationValue.value = newValue
      })
    }
    const onSubmitSelectCustomValue = (e: KeyboardEvent) => {
      if (e.code.indexOf('Enter') > -1) {
        e.preventDefault()
        submitCustomValue()
      }
    }

    const selectValues = computed(() => {
      if (ast.bIsIterable.value) {
        const presentOnly = {
          Present: AST_FUNCTIONS['Present'],
          NotPresent: AST_FUNCTIONS['NotPresent']
        }
        const uuid = ast.relation.value[ast.relationName.value][0]
        const att = store.state.byUUID[uuid]['Att']
        if (type == 'date') {
          return {
            Greater: AST_FUNCTIONS['Greater'],
            Less: AST_FUNCTIONS['Less'],
            Within: { name: 'Within' }
          }
        }
        if (type == 'system') {
          const all = {
            ...AstGroupComparison,
            Present: AST_FUNCTIONS['Present'],
            NotPresent: AST_FUNCTIONS['NotPresent'],
            Contains: AST_FUNCTIONS['Contains'],
            NotContains: AST_FUNCTIONS['NotContains']
          }
          const bIsDateAttribute = att
            ? isDateAttribute(systemAttributeToKey(att))
            : false

          return bIsDateAttribute ? presentOnly : all
        }
        if (['states', 'workflow'].includes(type)) {
          return {
            Equals: AST_FUNCTIONS['Equals'],
            NotEquals: AST_FUNCTIONS['NotEquals']
          }
        }
      } else {
        const bIsAttribute = ast.relationName.value.indexOf('Att') !== -1
        const values = bIsAttribute
          ? store.state.attributes
          : store.state.values
        return Array.from(values).filter(
          (val) =>
            val &&
            val.toLowerCase().includes(selectCustomValue.value.toLowerCase())
        )
      }
    })

    /** ------ */

    const removeRelation = () => {
      ast.removeRelation()
    }

    const parseRelation = (relationName: string): string => {
      const hash = {
        String: 'value',
        Att: 'attribute'
      }
      return hash[relationName] || relationName
    }

    watch(
      () => props.dataCondition,
      (newValue: string) => {
        const parsedAst = newValue.length > 0 ? Ast.parse(newValue) : false
        const ast: SuperAstType = Ast.denormalize(parsedAst)
        const state = Ast.toState(ast)

        store.commit('REPLACE_STORE', state)
      }
    )

    return {
      ...ast,
      uuid,
      parent,

      selectCompEl,
      onSelectValue,
      onOpenCustomSelect,
      selectCustomValue,
      onSubmitSelectCustomValue,
      submitCustomValue,
      selectValues,

      bShowFloatingMenu,
      onCreateGroup,
      onCreateCondition,

      removeRelation,
      parseRelation,
      parseValue,
      localizeText,
      type,
      simpleWidgetMatch,
      isLastSystem,
      isMain
    }
  }
})
</script>
